import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { defaultQuestions } from '../../../data/defaultQuestions';
import { Question } from '../../../models/question';
import { Cheese } from './components/Cheese/Cheese';
import './styles.scss';
import { CommonQuestionsProps } from './types';

class CommonQuestionsComponent extends Component<CommonQuestionsProps & WithTranslation> {
  public render(): JSX.Element {
    const { title, questions = defaultQuestions, t } = this.props;

    return (
      <div className={'common-questions'}>
        <h1>{title || t('faq.title')}</h1>
        {questions.map(
          ({ id, question, answer, list }: Question): JSX.Element => (
            <Cheese key={id} title={t(question)}>
              <p className={'common-questions__item__answer'}>{t(answer)}</p>
              <ul>
                {list && list.map((listItem: string): JSX.Element => <li key={`${id}-${listItem}`}>{t(listItem)}</li>)}
              </ul>
            </Cheese>
          ),
        )}
      </div>
    );
  }
}

export const CommonQuestions = withTranslation()(CommonQuestionsComponent);
