import { AppAction } from '../models/appAction';

export enum DeliveryDaysActionTypes {
  SetDeliveryDay = '[DeliveryDays] SetDeliveryDay',
}

interface SetDeliveryDayAction extends AppAction<{ day: string }> {
  type: typeof DeliveryDaysActionTypes.SetDeliveryDay;
}
export const setDeliveryDayAction: (id: string) => SetDeliveryDayAction = (day: string): SetDeliveryDayAction => ({
  type: DeliveryDaysActionTypes.SetDeliveryDay,
  payload: {
    day,
  },
});

export type DeliveryDaysActionsUnion = SetDeliveryDayAction;
