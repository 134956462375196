import TwelveMealsImage from '../assets/images/plans/12.png';
import SixMealsImage from '../assets/images/plans/6.png';
import NineMealsImage from '../assets/images/plans/9.png';
import { Plan } from '../models/plan';

export const plans: Plan[] = [
  {
    id: 4,
    mealsCount: 4,
    imageUrl:
      'https://res.cloudinary.com/freshly/image/upload/c_fill,f_auto,dpr_auto,q_auto:best/v1494958610/img-plan-4-v-1_2x_kszffp.jpg',
    mealPrice: {
      bh: 2.8,
      sa: 28,
      ae: 28,
      kw: 2.8,
      qa: 28,
    },
    weekPrice: {
      bh: 11.2,
      sa: 112,
      ae: 112,
      kw: 11.2,
      qa: 112,
    },
  },
  {
    id: 6,
    mealsCount: 6,
    imageUrl: SixMealsImage,
    mealPrice: {
      bh: 2.6,
      sa: 26,
      ae: 26,
      kw: 2.6,
      qa: 26,
    },
    weekPrice: {
      bh: 15.6,
      sa: 156,
      ae: 156,
      kw: 15.6,
      qa: 156,
    },
  },
  {
    id: 9,
    mealsCount: 9,
    imageUrl: NineMealsImage,
    mealPrice: {
      bh: 2.6,
      sa: 26,
      ae: 26,
      kw: 2.6,
      qa: 26,
    },
    weekPrice: {
      bh: 23.4,
      sa: 234,
      ae: 234,
      kw: 23.4,
      qa: 234,
    },
  },
  {
    id: 12,
    mealsCount: 12,
    imageUrl: TwelveMealsImage,
    mealPrice: {
      bh: 2.4,
      sa: 24,
      ae: 24,
      kw: 2.4,
      qa: 24,
    },
    weekPrice: {
      bh: 28.8,
      sa: 288,
      ae: 288,
      kw: 28.8,
      qa: 288,
    },
  },
];
