import { Question } from '../models/question';

export const defaultQuestions: Question[] = [
  {
    id: 1,
    question: 'faq.howManyPortionsInMeals.question',
    answer: 'faq.howManyPortionsInMeals.answer',
  },
  {
    id: 2,
    question: 'faq.preferences.question',
    answer: 'faq.preferences.answer',
    list: [
      'faq.preferences.calories',
      'faq.preferences.carbs',
      'faq.preferences.dairyFree',
      'faq.preferences.paleo',
      'faq.preferences.vegeterian',
    ],
  },
  {
    id: 3,
    question: 'faq.howLong.question',
    answer: 'faq.howLong.answer',
  },
  {
    id: 4,
    question: 'faq.heatUp.question',
    answer: 'faq.heatUp.answer',
  },
  {
    id: 5,
    question: 'faq.shipping.question',
    answer: 'faq.shipping.answer',
  },
  {
    id: 6,
    question: 'faq.subscription.question',
    answer: 'faq.subscription.answer',
  },
];
