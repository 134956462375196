import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { State } from '../../../reducers';
import { getUserCity } from '../../../reducers/selectors/user';
import './styles.scss';
import { ThankYouProps } from './types';

class ThankYouComponent extends Component<ThankYouProps & WithTranslation> {
  public render(): JSX.Element {
    const { t } = this.props;

    return (
      <div className={'thank-you-page app-content'}>
        <h3 className={'app-header'}>{t('thankYou').replace('{cityName}', this.props.city)}</h3>
      </div>
    );
  }
}

const mapStateToProps = (state: State): ThankYouProps => ({
  city: getUserCity(state) || 'your city',
});

export const ThankYou = connect(mapStateToProps)(withTranslation()(ThankYouComponent));
