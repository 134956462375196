import { Row } from 'antd';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import {
  setAddress1Action,
  setAddress2Action,
  setEmailAction,
  setFirstNameAction,
  setLastNameAction,
  setPaymentMethodAction,
  setPhoneAction,
} from '../../../actions/user';
import { getCurrencyNameByCode } from '../../../helpers/CurrencyNamer';
import { FirebaseDatabaseWriter } from '../../../helpers/FirebaseDatabaseWriter';
import { AppLocalization } from '../../../models/appLocalization';
import { State } from '../../../reducers';
import { getCurrency } from '../../../reducers/selectors/currency';
import { getActiveDeliveryDay } from '../../../reducers/selectors/deliveryDays';
import { getSelectedMealsIds } from '../../../reducers/selectors/meals';
import { getPlanById, getSelectedPlanId } from '../../../reducers/selectors/plans';
import { getUserCity, getUserEmail, getUserFirstName, getUserLastName } from '../../../reducers/selectors/user';
import { CartItem } from '../../local/CartItem';
import { CommonQuestions } from '../../local/CommonQuestions';
import { FillDeliveryAddressForm } from '../../local/FillDeliveryAddressForm';
import { FillDeliveryAddressFormValues } from '../../local/FillDeliveryAddressForm/types';
import { FillUserInfoForm } from '../../local/FillUserInfoForm';
import { FillUserInfoFormValues } from '../../local/FillUserInfoForm/types';
import { SelectPaymentMethodForm } from '../../local/SelectPaymentMethodForm';
import { SelectPaymentMethodFormValues } from '../../local/SelectPaymentMethodForm/types';
import { Tile } from './components/Tile';
import './styles.scss';
import { CheckOutDispatchProps, CheckoutProps, CheckoutState, CheckoutStateProps } from './types';

export class CheckoutComponent extends Component<CheckoutProps & WithTranslation, CheckoutState> {
  constructor(props: CheckoutProps & WithTranslation) {
    super(props);

    this.state = {
      step: 'fill-info',
    };
  }

  public render(): JSX.Element {
    const {
      selectedMealsIds,
      plan,
      userEmail,
      userCity,
      userFirstName,
      userLastName,
      deliveryDay,
      t,
      currency,
    } = this.props;
    const { id: planId, weekPrice } = plan;
    const { step } = this.state;

    return (
      <div className={'checkout-page app-content'}>
        <div className={'checkout-page__content'}>
          <div className={'checkout-page__content__left-column'}>
            <Tile header={`1 - ${t('checkout.fillYourInfo.title')}`}>
              {step === 'fill-info' ? (
                <div className={'checkout-page__content__left-column__form-wrapper'}>
                  <FillUserInfoForm initialValues={{ email: userEmail }} onSubmit={this.handleUserInfoFormSubmit} />
                </div>
              ) : null}
            </Tile>
            <Tile header={`2 - ${t('checkout.deliveryAddress.title')}`}>
              {step === 'fill-address' ? (
                <div className={'checkout-page__content__left-column__form-wrapper'}>
                  <FillDeliveryAddressForm
                    initialValues={{ city: userCity, 'full-name': `${userFirstName} ${userLastName}` }}
                    onSubmit={this.handleAddressFormSubmit}
                  />
                </div>
              ) : null}
            </Tile>
            <Tile header={`3 - ${t('checkout.paymentInfo.title')}`}>
              {step === 'choose-payment-method' ? (
                <div className={'checkout-page__content__left-column__form-wrapper'}>
                  <SelectPaymentMethodForm onSubmit={this.handlePaymentFormSubmit} />
                </div>
              ) : null}
            </Tile>
          </div>
          <div className={'checkout-page__content__right-column'}>
            <Tile header={t('checkout.orderSummary.title')}>
              <Row className={'checkout-page__content__right-column__row'} type={'flex'} justify={'space-between'}>
                <p className={'checkout-page__content__right-column__row__row-item'}>
                  {t('checkout.orderSummary.day')}
                </p>
                <p className={'checkout-page__content__right-column__row__row-item'}>{t(`common.${deliveryDay}`)}</p>
              </Row>
              <Row className={'checkout-page__content__right-column__row'} type={'flex'} justify={'space-between'}>
                <p className={'checkout-page__content__right-column__row__row-item'}>{`${planId} ${t(
                  'checkout.orderSummary.mealsPerWeek',
                )}`}</p>
                <p className={'checkout-page__content__right-column__row__row-item'}>{`${getCurrencyNameByCode(
                  currency,
                )} ${weekPrice[currency]}`}</p>
              </Row>
              <Row className={'checkout-page__content__right-column__row'} type={'flex'} justify={'space-between'}>
                <p className={'checkout-page__content__right-column__row__row-item'}>
                  {t('checkout.orderSummary.shipping')}
                </p>
                <p className={'checkout-page__content__right-column__row__row-item'}>
                  <b>{t('common.free')}</b>
                </p>
              </Row>
              <Row className={'checkout-page__content__right-column__row'} type={'flex'} justify={'space-between'}>
                <p className={'checkout-page__content__right-column__row__row-item'}>
                  <b>{t('checkout.orderSummary.totalToday')}</b>
                </p>
                <p className={'checkout-page__content__right-column__row__row-item'}>
                  <b>{`${getCurrencyNameByCode(currency)} ${weekPrice[currency]}`}</b>
                </p>
              </Row>
            </Tile>
            <div className={'checkout-page__content__right-column__meals'}>
              <Tile header={t('checkout.myMeals.title')}>
                {selectedMealsIds.map((id: number) => (
                  <CartItem key={id} mealId={id} />
                ))}
              </Tile>
            </div>
          </div>
        </div>
        <CommonQuestions />
      </div>
    );
  }

  private handleUserInfoFormSubmit = ({ email, firstName, lastName }: FillUserInfoFormValues): void => {
    const { handleSetUserEmail, handleSetUserFirstName, handleSetUserLastName } = this.props;
    handleSetUserLastName(lastName);
    handleSetUserFirstName(firstName);
    handleSetUserEmail(email);
    this.setState({ step: 'fill-address' });
  };

  private handleAddressFormSubmit = (values: FillDeliveryAddressFormValues): void => {
    const { handleSetUserAddress1, handleSetUserAddress2, handleSetUserPhone } = this.props;
    handleSetUserAddress1(values['address-line-1']);
    handleSetUserAddress2(values['address-line-2']);
    handleSetUserPhone(values.phone);
    this.setState({ step: 'choose-payment-method' });
  };

  private handlePaymentFormSubmit = (values: SelectPaymentMethodFormValues): void => {
    const { history, handleSetUserPaymentMethod } = this.props;
    handleSetUserPaymentMethod(values['payment-method']);
    const databaseWriter = new FirebaseDatabaseWriter();
    databaseWriter.writeUserData();
    history.push('checkout-success');
  };
}

const mapStateToProps = (state: State): CheckoutStateProps => {
  const planId = getSelectedPlanId(state) || 0;

  return {
    selectedMealsIds: getSelectedMealsIds(state),
    userCity: getUserCity(state) || '',
    userEmail: getUserEmail(state) || '',
    userFirstName: getUserFirstName(state) || '',
    userLastName: getUserLastName(state) || '',
    deliveryDay: getActiveDeliveryDay(state),
    plan: getPlanById(planId)(state),
    currency: getCurrency(state) as AppLocalization,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): CheckOutDispatchProps => ({
  handleSetUserEmail: (email: string): void => {
    dispatch(setEmailAction(email));
  },
  handleSetUserFirstName: (name: string): void => {
    dispatch(setFirstNameAction(name));
  },
  handleSetUserLastName: (name: string): void => {
    dispatch(setLastNameAction(name));
  },
  handleSetUserAddress1: (address: string): void => {
    dispatch(setAddress1Action(address));
  },
  handleSetUserAddress2: (address: string): void => {
    dispatch(setAddress2Action(address));
  },
  handleSetUserPhone: (phone: string): void => {
    dispatch(setPhoneAction(phone));
  },
  handleSetUserPaymentMethod: (method: string): void => {
    dispatch(setPaymentMethodAction(method));
  },
});

export const Checkout = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(CheckoutComponent));
