import { Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { selectMealAction } from '../../../../../actions/meals';
import { State } from '../../../../../reducers';
import { getMealById } from '../../../../../reducers/selectors/meals';
import './styles.scss';
import { MealModalComponentProps, MealModalDispatchProps, MealModalProps, MealModalStateProps } from './types';

class MealModalComponent extends Component<MealModalProps> {
  public render(): JSX.Element | null {
    const { meal, mealId, ...props } = this.props;

    if (meal) {
      const { name, imageUrl, description, ingredients, protein, calories, carbs } = meal;

      return (
        <React.Fragment>
          <Modal footer={null} title={name} {...props}>
            <img src={imageUrl} alt={name} width={'100%'} className={'meal-modal__image'} />
            <p className={'meal-modal__description'}>{description}</p>
            <div className={'meal-modal__content'}>
              <div className={'meal-modal__content__item'}>
                <h3 className={'app-header'}>{`${protein}g`}</h3>
                <p>{'Protein'}</p>
              </div>
              <div className={'meal-modal__content__item'}>
                <h3 className={'app-header'}>{calories}</h3>
                <p>{'Calories'}</p>
              </div>
              <div className={'meal-modal__content__item'}>
                <h3 className={'app-header'}>{`${carbs}g`}</h3>
                <p>{'Carbs'}</p>
              </div>
            </div>
            <p className={'meal-modal__description'}>
              <b>{'Ingredients: '}</b>
              {ingredients}
            </p>
          </Modal>
        </React.Fragment>
      );
    }

    return null;
  }
}

const mapStateToProps = (state: State, { mealId }: MealModalComponentProps): MealModalStateProps => {
  const meal = mealId ? getMealById(mealId)(state) : undefined;

  return {
    meal,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
  { mealId }: MealModalComponentProps,
): MealModalDispatchProps => ({
  handleSelectMeal: (): void => {
    if (mealId) {
      dispatch(selectMealAction(mealId));
    }
  },
});

export const MealModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MealModalComponent);
