import { Radio } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React, { Component } from 'react';
import { WrappedFieldProps } from 'redux-form';

export class RadioField extends Component<WrappedFieldProps & { label: string; hasFeedback: boolean }> {
  public render(): JSX.Element {
    const { input, meta, children, hasFeedback, label, ...rest } = this.props;
    const hasError = meta.touched && meta.invalid;

    return (
      <FormItem
        label={label}
        validateStatus={hasError ? 'error' : 'success'}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
      >
        <Radio.Group
          {...input}
          {...rest}
        />
      </FormItem>
    );
  }
}
