import { Reducer } from 'redux';
import { DeliveryDaysActionsUnion, DeliveryDaysActionTypes } from '../actions/deliveryDays';

export interface State {
  collection: string[];
  activeDeliveryDay: string;
}

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const initialState: State = {
  collection: days,
  activeDeliveryDay: days[0],
};

export const reducer: Reducer<State, DeliveryDaysActionsUnion> = (
  state: State = initialState,
  action: DeliveryDaysActionsUnion,
): State => {
  switch (action.type) {
    case DeliveryDaysActionTypes.SetDeliveryDay:
      return {
        ...state,
        activeDeliveryDay: action.payload.day,
      };

    default:
      return state;
  }
};

export const getCollection = ({ collection }: State): string[] => collection;
export const getActiveDeliveryDay = ({ activeDeliveryDay }: State): string => activeDeliveryDay;
