import Fingerprint2, { Component as FingerprintComponent } from 'fingerprintjs2';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { combineReducers, createStore } from 'redux';
import './App.scss';
import { Home } from './components/pages/Home';
import { JoinNow } from './components/pages/JoinNow';
import { TermsAndConditions } from './components/pages/TermsAndConditions';
import './i18n';
import { appReducer } from './reducers';
import { ScrollToTop } from './ScrollToTop';

export const store = createStore(
  combineReducers(appReducer),
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

class App extends Component<WithTranslation> {
  public componentDidMount(): void {
    Fingerprint2.get({ excludes: { userAgent: true } }, (components: FingerprintComponent[]) => {
      const values = components.map((component: FingerprintComponent) => component.value);
      const murmur = Fingerprint2.x64hash128(values.join(''), 31);
      localStorage.setItem('identity', murmur);
    });
  }

  public render(): JSX.Element {
    const { i18n } = this.props;

    return (
      <div className={i18n.language === 'ar' ? 'arabic-text' : ''}>
        <Provider store={store}>
          <Router>
            <ScrollToTop>
              <Route path={'/'} exact component={Home} />
              <Route path={'/sa'} exact component={Home} />
              <Route path={'/ae'} exact component={Home} />
              <Route path={'/kw'} exact component={Home} />
              <Route path={'/qa'} exact component={Home} />
              <Route path={'/join-now'} component={JoinNow} />
              <Route path={'/terms'} component={TermsAndConditions} />
            </ScrollToTop>
          </Router>
        </Provider>
      </div>
    );
  }
}

export default withTranslation()(App);
