export const SignInFormName = 'sign-in-form';

export enum SignInFormFieldNames {
  Email = 'email',
  City = 'city',
}

export interface SignInFormValues {
  [SignInFormFieldNames.Email]: string;
  [SignInFormFieldNames.City]: string;
}

export interface SignInFormState {
  suggestionValues: string[];
  selectedValue: string;
}
