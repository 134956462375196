import { Meal } from '../models/meal';

import data from './meals.json';

export const meals: Meal[] = data.data.map(
  // tslint:disable-next-line:typedef
  ({
    id,
    name,
    description,
    meal_image_urls: { medium: imageUrl },
    display_name,
    ingredients,
    nutrition_with_daily_values: {
      calories: { value: caloriesValue },
      carbohydrates: { value: carbsValue },
      protein: { value: proteinValue },
      serving_unit: { value: servingUnit },
      serving_size: { value: servingSize },
    },
  }): Meal => {
    const withStringIndex = display_name.indexOf('with');
    const subtitle = withStringIndex > 0 ? display_name.substring(withStringIndex) : undefined;
    return {
      id,
      name,
      description,
      subtitle,
      calories: caloriesValue,
      carbs: carbsValue,
      imageUrl,
      protein: proteinValue,
      servingSize,
      servicePerContainer: servingUnit,
      ingredients,
    };
  },
);
