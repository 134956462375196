import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router';
import { State } from '../../reducers';
import { getUserCity, getUserEmail } from '../../reducers/selectors/user';
import { PrivateRouteProps } from './types';

class PrivateRouteComponent extends Component<RouteProps & PrivateRouteProps> {
  public render(): JSX.Element {
    const { component, ...rest } = this.props;

    return <Route {...rest} render={this.renderRoute} />;
  }

  private renderRoute = (props: RouteComponentProps): JSX.Element => {
    const { component: RouteComponent, isUserFilled } = this.props;

    if (isUserFilled && RouteComponent) {
      return <RouteComponent {...props} />;
    }

    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location },
        }}
      />
    );
  };
}

const mapStateToProps = (state: State): PrivateRouteProps => ({
  isUserFilled: Boolean(getUserEmail(state) && getUserCity(state)),
});

export const PrivateRoute = connect(mapStateToProps)(PrivateRouteComponent);
