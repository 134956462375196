import { ReducersMapObject } from 'redux';
import { FormReducer, reducer as formReducer } from 'redux-form';

import * as fromCurrency from './currency';
import * as fromDeliveryDays from './deliveryDays';
import * as fromMeals from './meals';
import * as fromPlans from './plans';
import * as fromUser from './user';

export interface State {
  currency: fromCurrency.State;
  deliveryDays: fromDeliveryDays.State;
  meals: fromMeals.State;
  plans: fromPlans.State;
  user: fromUser.State;
  form: FormReducer;
}

export const appReducer: ReducersMapObject<State, any> = {
  currency: fromCurrency.reducer,
  deliveryDays: fromDeliveryDays.reducer,
  meals: fromMeals.reducer,
  plans: fromPlans.reducer,
  user: fromUser.reducer,
  form: formReducer as any,
};
