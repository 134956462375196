import { Reducer } from 'redux';
import { PlansActionsUnion, PlansActionTypes } from '../actions/plans';
import { plans } from '../data/plans';
import { Plan } from '../models/plan';

export interface State {
  collection: Plan[];
  selectedPlanId: number | null;
}

const initialState: State = {
  collection: plans,
  selectedPlanId: null,
};

export const reducer: Reducer<State, PlansActionsUnion> = (
  state: State = initialState,
  action: PlansActionsUnion,
): State => {
  switch (action.type) {
    case PlansActionTypes.SetActivePlan:
      return {
        ...state,
        selectedPlanId: action.payload.id,
      };

    default:
      return state;
  }
};

export const getCollection = ({ collection }: State): Plan[] => collection;
export const getSelectedPlanId = ({ selectedPlanId }: State): number | null => selectedPlanId;
export const getPlanById = (id: number): ((state: State) => Plan) => ({ collection }: State): Plan =>
  collection.find((item: Plan): boolean => item.id === id) || collection[0];
