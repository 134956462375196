import { HowItWorksRecord } from '../../../../models/howItWorksRecord';
import ChooseYourMealsGif from '../assets/images/how-it-works-choose-your-meals.gif';
import WeCookAndDeliverGif from '../assets/images/how-it-works-cook-and-deliver.gif';
import EatAndRepeatGif from '../assets/images/how-it-works-eat-and-repeat.gif';
import HeatThemGif from '../assets/images/how-it-works-heat-them.gif';

export const howItWorksSteps: HowItWorksRecord[] = [
  { imageUrl: ChooseYourMealsGif, title: 'home.howItWorks.chooseYourMeals.title', description: 'home.howItWorks.chooseYourMeals.description' },
  { imageUrl: WeCookAndDeliverGif, title: 'home.howItWorks.cookAndDeliver.title', description: 'home.howItWorks.cookAndDeliver.description' },
  { imageUrl: HeatThemGif, title: 'home.howItWorks.heatThem.title', description: 'home.howItWorks.heatThem.description' },
  { imageUrl: EatAndRepeatGif, title: 'home.howItWorks.eatAndRepeat.title', description: 'home.howItWorks.eatAndRepeat.description' },
];
