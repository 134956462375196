import { Reducer } from 'redux';
import { UserActionsUnion, UserActionTypes } from '../actions/user';

export interface State {
  email: string | null;
  city: string | null;
  firstName: string | null;
  lastName: string | null;
  address1: string | null;
  address2: string | null;
  phone: string | null;
  paymentMethod: string | null;
}

const initialState: State = {
  email: null,
  city: null,
  firstName: null,
  lastName: null,
  address1: null,
  address2: null,
  phone: null,
  paymentMethod: null,
};

export const reducer: Reducer<State, UserActionsUnion> = (
  state: State = initialState,
  action: UserActionsUnion,
): State => {
  switch (action.type) {
    case UserActionTypes.SetCity:
      return {
        ...state,
        city: action.payload.city,
      };

    case UserActionTypes.SetEmail:
      return {
        ...state,
        email: action.payload.email,
      };

    case UserActionTypes.SetFirstName:
      return {
        ...state,
        firstName: action.payload.firstName,
      };

    case UserActionTypes.SetLastName:
      return {
        ...state,
        lastName: action.payload.lastName,
      };

    case UserActionTypes.SetAddress1:
      return {
        ...state,
        address1: action.payload.address,
      };

    case UserActionTypes.SetAddress2:
      return {
        ...state,
        address2: action.payload.address,
      };

    case UserActionTypes.SetPhone:
      return {
        ...state,
        phone: action.payload.phone,
      };

    case UserActionTypes.SetPaymentMethod:
      return {
        ...state,
        paymentMethod: action.payload.paymentMethod,
      };

    default:
      return state;
  }
};

export const getEmail = ({ email }: State): string | null => email;
export const getCity = ({ city }: State): string | null => city;
export const getFirstName = ({ firstName }: State): string | null => firstName;
export const getLastName = ({ lastName }: State): string | null => lastName;
export const getAddress1 = ({ address1 }: State): string | null => address1;
export const getAddress2 = ({ address2 }: State): string | null => address2;
export const getPhone = ({ phone }: State): string | null => phone;
export const getPaymentMethod = ({ paymentMethod }: State): string | null => paymentMethod;
