import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React, { Component } from 'react';
import { WrappedFieldProps } from 'redux-form';

export class TextInput extends Component<WrappedFieldProps & { label: string; hasFeedback: boolean }> {
  public render(): JSX.Element {
    const { input, meta, children, hasFeedback, label, ...rest } = this.props;
    const hasError = meta.touched && meta.invalid;

    return (
      <FormItem
        label={label}
        style={{ marginBottom: 10 }}
        validateStatus={hasError ? 'error' : 'success'}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
      >
        <Input size={'large'} {...input} {...rest} children={children} />
      </FormItem>
    );
  }
}
