import { Icon } from 'antd';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { PrivateRoute } from '../../../helpers/PrivateRoute';
import { Header } from '../../local/Header';
import { Checkout } from '../Checkout';
import { ChooseDeliveryDay } from '../ChooseDeliveryDay';
import { ChoosePlan } from '../ChoosePlan';
import { SelectMeals } from '../SelectMeals';
import { ThankYou } from '../ThankYou';
import './styles.scss';

class JoinNowComponent extends Component<RouteComponentProps & WithTranslation> {
  public render(): JSX.Element {
    const {
      match,
      location: { pathname },
      t,
    } = this.props;
    const url = pathname.substring(pathname.lastIndexOf('/'));

    return (
      <div className={'join-now-page'}>
        <Header>
          <div className={'join-now-page__header-content small'}>
            <p className={'join-now-page__header-content__item selected'}>
              {url === '/join-now' ? t('common.plan') : null}
              {url === '/day' ? t('common.day') : null}
              {url === '/meals' ? t('common.meals') : null}
              {url === '/checkout' ? t('common.checkout') : null}
            </p>
          </div>
          <div className={'join-now-page__header-content large'}>
            <p className={`join-now-page__header-content__item ${url === '/join-now' ? 'selected' : ''}`}>{t('common.plan')}</p>
            <Icon type={'right'} className={'join-now-page__header-content__icon'} />
            <p className={`join-now-page__header-content__item ${url === '/day' ? 'selected' : ''}`}>{t('common.day')}</p>
            <Icon type={'right'} className={'join-now-page__header-content__icon'} />
            <p className={`join-now-page__header-content__item ${url === '/meals' ? 'selected' : ''}`}>{t('common.meals')}</p>
            <Icon type={'right'} className={'join-now-page__header-content__icon'} />
            <p className={`join-now-page__header-content__item ${url === '/checkout' ? 'selected' : ''}`}>
              {t('common.checkout')}
            </p>
          </div>
        </Header>
        <div className={'header-spacer'} />

        <PrivateRoute exact path={match.path} component={ChoosePlan} />
        <PrivateRoute exact path={`${match.path}/day`} component={ChooseDeliveryDay} />
        <PrivateRoute exact path={`${match.path}/meals`} component={SelectMeals} />
        <PrivateRoute exact path={`${match.path}/checkout`} component={Checkout} />
        <PrivateRoute exact path={`${match.path}/checkout-success`} component={ThankYou} />
      </div>
    );
  }
}

export const JoinNow = withTranslation()(JoinNowComponent);
