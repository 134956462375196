import { AppAction } from '../models/appAction';

export enum PlansActionTypes {
  SetActivePlan = '[Plans] SetActivePlan',
}

interface SetActivePlanAction extends AppAction<{ id: number }> {
  type: typeof PlansActionTypes.SetActivePlan;
}
export const setActivePlanAction: (id: number) => SetActivePlanAction = (id: number): SetActivePlanAction => ({
  type: PlansActionTypes.SetActivePlan,
  payload: {
    id,
  },
});

export type PlansActionsUnion = SetActivePlanAction;
