import { Button, Col, Row } from 'antd';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Validator } from '../../../helpers/Validator';
import { TextInput } from '../TextInput';
import './styles.scss';
import { FillUserInfoFormFieldNames, FillUserInfoFormName, FillUserInfoFormValues } from './types';

class FillUserInfoFormComponent extends Component<InjectedFormProps<FillUserInfoFormValues> & WithTranslation> {
  public render(): JSX.Element {
    const { handleSubmit, t } = this.props;

    return (
      <form className={'fill-user-info-form'} onSubmit={handleSubmit}>
        <Row>
          <Col className={'fill-user-info-form__input'} span={12}>
            <Field
              name={FillUserInfoFormFieldNames.FirstName}
              component={TextInput}
              label={t('checkout.fillYourInfo.firstName')}
              validate={[Validator.required]}
            />
          </Col>
          <Col className={'fill-user-info-form__input'} span={12}>
            <Field
              name={FillUserInfoFormFieldNames.LastName}
              component={TextInput}
              label={t('checkout.fillYourInfo.lastName')}
              validate={[Validator.required]}
            />
          </Col>
        </Row>
        <Field
          name={FillUserInfoFormFieldNames.Email}
          component={TextInput}
          size={'large'}
          label={t('common.email')}
          validate={[Validator.required, Validator.email]}
        />
        <Button
          size={'large'}
          className={'fill-user-info-form__submit-button'}
          type={'primary'}
          block
          htmlType={'submit'}
        >
          {t('common.next')}
        </Button>
      </form>
    );
  }
}

export const FillUserInfoForm = reduxForm<FillUserInfoFormValues>({
  form: FillUserInfoFormName,
})(withTranslation()(FillUserInfoFormComponent));
