import { AutoComplete } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React, { Component } from 'react';
import { WrappedFieldProps } from 'redux-form';

export class AutoCompleteField extends Component<WrappedFieldProps & { label: string; hasFeedback: boolean }> {
  public render(): JSX.Element {
    const { input, meta, children, hasFeedback, label, ...rest } = this.props;
    const hasError = meta.touched && meta.invalid;

    return (
      <FormItem
        label={label}
        validateStatus={hasError ? 'error' : 'success'}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
      >
        <AutoComplete
          size={'large'}
          {...input}
          {...rest}
          onFocus={(): void => {
            input.onFocus(new Event('AutoComplete Focus') as any);
          }}
        />
      </FormItem>
    );
  }
}
