import { Reducer } from 'redux';
import { MealActionsUnion, MealActionTypes } from '../actions/meals';
import { meals } from '../data/meals';
import { Meal } from '../models/meal';
import { NumbersMap } from '../models/numbersMap';

export interface State {
  selected: NumbersMap;
  collection: Meal[];
}

export const initialState: State = {
  selected: {},
  collection: meals,
};

export const reducer: Reducer<State, MealActionsUnion> = (
  state: State = initialState,
  action: MealActionsUnion,
): State => {
  switch (action.type) {
    case MealActionTypes.SelectMeal:
      const { id: selectedMealId } = action.payload;
      return {
        ...state,
        selected: {
          ...state.selected,
          [selectedMealId]: state.selected[selectedMealId] ? state.selected[selectedMealId] + 1 : 1,
        },
      };

    case MealActionTypes.UnselectMeal:
      const { id: unselectedMealId } = action.payload;
      const selected: NumbersMap = Object.assign({}, state.selected);
      if (selected[unselectedMealId]) {
        if (selected[unselectedMealId] === 1) {
          delete selected[unselectedMealId];
        } else {
          selected[unselectedMealId] = selected[unselectedMealId] - 1;
        }
      }
      return {
        ...state,
        selected,
      };

    default:
      return state;
  }
};

export const getMeals = ({ collection }: State): Meal[] => collection;
export const getSelectedMealsIds = ({ selected }: State): number[] =>
  Object.keys(selected).map((key: string): number => +key);
export const getMealById = (id: number): ((state: State) => Meal | undefined) => ({
  collection,
}: State): Meal | undefined => collection.find((meal: Meal): boolean => meal.id === id);
export const getCountSelectedOfMeal = (id: number): ((state: State) => number) => ({ selected }: State): number =>
  selected[id] || 0;
export const getCountOfSelectedMeals = (state: State): number =>
  getSelectedMealsIds(state).reduce(
    (previous: number, current: number): number => (previous += state.selected[current]),
    0,
  );
