export const normalizePhone = (value: string): string => {
  if (!value) {
    return value;
  }

  const onlyNumbers = value.replace(/[^\d]/g, '');
  if (onlyNumbers.length <= 3) {
    return onlyNumbers;
  }
  if (onlyNumbers.length <= 7) {
    return `${onlyNumbers.slice(0, 3)}-${onlyNumbers.slice(3)}`;
  }
  return `${onlyNumbers.slice(0, 3)}-${onlyNumbers.slice(3, 6)}-${onlyNumbers.slice(6, 10)}`;
};
