import { Button } from 'antd';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { googlePlacesRequest } from '../../../helpers/GooglePlacesRequest';
import { Validator } from '../../../helpers/Validator';
import { AutoCompleteField } from '../AutoCompleteField';
import { TextInput } from '../TextInput';
import './styles.scss';
import { SignInFormFieldNames, SignInFormName, SignInFormState, SignInFormValues } from './types';

class SignInFormComponent extends Component<InjectedFormProps<SignInFormValues> & WithTranslation, SignInFormState> {
  constructor(props: InjectedFormProps<SignInFormValues> & WithTranslation) {
    super(props);

    this.state = {
      suggestionValues: [],
      selectedValue: '',
    };
  }

  public render(): JSX.Element {
    const { handleSubmit, t } = this.props;

    return (
      <form className={'sign-in-form'} onSubmit={handleSubmit}>
        <div className={'sign-in-form__inputs-row'}>
          <div className={'sign-in-form__inputs-row__input'}>
            <Field
              name={SignInFormFieldNames.Email}
              component={TextInput}
              placeholder={t('common.email')}
              validate={[Validator.email, Validator.required]}
            />
          </div>
          <div className={'sign-in-form__inputs-row__input'}>
            <Field
              name={SignInFormFieldNames.City}
              component={AutoCompleteField}
              placeholder={t('common.city')}
              validate={[
                Validator.required,
                // (value: string): boolean => {
                //   if (this.state.selectedValue === value) {
                //     return true;
                //   }
                //   return false;
                // },
              ]}
              onSearch={this.handleCityValueChange as any}
              dataSource={this.state.suggestionValues}
              onSelect={this.handleSelect as any}
            />
          </div>
        </div>
        <Button size={'large'} type={'primary'} block htmlType={'submit'}>
          {t('signIn.getStarted')}
        </Button>

        <p className={'sign-in-form__note'}>
          <Link to={{ pathname: '/terms' }}>{t('signIn.agreeToTerms')}</Link>
        </p>
      </form>
    );
  }

  private handleCityValueChange = async (value: string): Promise<void> => {
    if (value && value.length) {
      const results = await googlePlacesRequest(value);
      if (results) {
        this.setState({ suggestionValues: results });
      }
    } else {
      this.setState({ suggestionValues: [] });
    }
  };

  private handleSelect = (value: string): void => {
    this.setState({
      selectedValue: value,
    });
  };
}

export const SignInForm = reduxForm<SignInFormValues>({
  form: SignInFormName,
})(withTranslation()(SignInFormComponent));
