import GoogleMapsLoader, { google } from 'google-maps';

GoogleMapsLoader.KEY = 'AIzaSyBMwGb0K0hMZi15QNflGliuVuWe6kD9BDs';
GoogleMapsLoader.LIBRARIES = ['places'];

export interface QuerySuggestion {
  description: string;
}

export const googlePlacesRequest = (query: string): Promise<string[]> => {
  return new Promise(
    (resolve: (values: string[]) => void, reject: (error: any) => void): void => {
      GoogleMapsLoader.load(
        (googleLibrary: google): void => {
          const maps = new googleLibrary.maps.places.AutocompleteService();
          maps.getPlacePredictions(
            { input: query, types: ['(cities)'], componentRestrictions: { country: ['bh', 'kw', 'sa', 'ae', 'qa'] } },
            (results: QuerySuggestion[]): void => {
              if (results) {
                resolve(results.map((result: QuerySuggestion): string => result.description));
              } else {
                reject('Find places error');
              }
            },
          );
        },
      );
    },
  );
};
