import { Icon } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { selectMealAction, unselectMealAction } from '../../../actions/meals';
import { State } from '../../../reducers';
import { getCountSelectedOfMeal, getMealById } from '../../../reducers/selectors/meals';
import './styles.scss';
import { CartItemComponentProps, CartItemDispatchProps, CartItemProps, CartItemStateProps } from './types';

class CartItemComponent extends Component<CartItemProps> {
  public render(): JSX.Element {
    const { imageUrl, title, subtitle, mealCount, showControls = false, handleRemove, handleAdd } = this.props;

    return (
      <div className={'cart-item'}>
        <div className={'cart-item__controls-container'}>
          {showControls ? (
            <Icon onClick={handleAdd} type={'plus'} className={'cart-item__controls-container__add-item'} />
          ) : null}
          <p>{mealCount}</p>
          {showControls ? (
            <Icon onClick={handleRemove} type={'minus'} className={'cart-item__controls-container__remove-item'} />
          ) : null}
        </div>
        <img src={imageUrl} alt={title} />
        <div className={'cart-item__content'}>
          <h4 className={'app-header'}>{title}</h4>
          <p className={'app-item-description'}>
            <i>{subtitle}</i>
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State, { mealId }: CartItemComponentProps): CartItemStateProps => {
  const meal = getMealById(mealId)(state);
  if (meal) {
    const { name, subtitle, imageUrl } = meal;
    const mealCount = getCountSelectedOfMeal(meal.id)(state);
    return {
      subtitle,
      title: name,
      imageUrl,
      mealCount,
    };
  }

  return {
    title: 'Error',
    imageUrl: '',
    subtitle: undefined,
    mealCount: 0,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
  { mealId, showControls }: CartItemComponentProps,
): CartItemDispatchProps => ({
  handleAdd: (): void => {
    if (showControls) {
      dispatch(selectMealAction(mealId));
    }
  },
  handleRemove: (): void => {
    if (showControls) {
      dispatch(unselectMealAction(mealId));
    }
  },
});

export const CartItem = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CartItemComponent);
