import { ImageWithTitle } from '../../../../models/imageWithTitle';

export const mealAdvantages: ImageWithTitle[] = [
  {
    imageUrl: 'https://res.cloudinary.com/freshly/image/upload/q_auto:best,dpr_auto/v1494958610/Ingredients_02_2x.png',
    title: 'home.mealAdvantages.protein',
  },
  {
    imageUrl: 'https://res.cloudinary.com/freshly/image/upload/q_auto:best,dpr_auto/v1494958610/Ingredients_03_2x.png',
    title: 'home.mealAdvantages.sugars',
  },
  {
    imageUrl: 'https://res.cloudinary.com/freshly/image/upload/q_auto:best,dpr_auto/v1494958610/Ingredients_04_2x.png',
    title: 'home.mealAdvantages.natural',
  },
];
