import { createSelector } from 'reselect';
import { getDeliveryDaysState } from '.';

import * as fromDeliveryDays from '../deliveryDays';

export const getDeliveryDays = createSelector(
  getDeliveryDaysState,
  fromDeliveryDays.getCollection,
);
export const getActiveDeliveryDay = createSelector(
  getDeliveryDaysState,
  fromDeliveryDays.getActiveDeliveryDay,
);
