import { Button, Row } from 'antd';
import React, { Component, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { selectMealAction, unselectMealAction } from '../../../actions/meals';
import { State } from '../../../reducers';
import { getCountSelectedOfMeal, getMealById } from '../../../reducers/selectors/meals';
import './styles.scss';
import { MealCardComponentProps, MealCardDispatchProps, MealCardProps } from './types';

class MealCardComponent extends Component<MealCardComponentProps & MealCardProps & MealCardDispatchProps> {
  public render(): JSX.Element {
    const { title, subtitle, imageUrl, mealCount, onCardClick } = this.props;

    return (
      <div className={'meal-card'} onClick={onCardClick}>
        <div className={'meal-card__image'} style={{ backgroundImage: `url(${imageUrl})` }} />
        <div className={'meal-card__body'}>
          <Row
            type={'flex'}
            justify={'space-between'}
            align={'middle'}
            className={'meal-card__body__controls-container'}
          >
            <Button disabled={mealCount === 0} onClick={this.handleRemoveButtonClick}>
              {'-'}
            </Button>
            <div className={'meal-card__body__count-wrapper'}>
              <p>{mealCount}</p>
            </div>
            <Button onClick={this.handleAddButtonClick}>{'+'}</Button>
          </Row>
          <h4 className={'app-header'}>{title}</h4>
          {subtitle ? (
            <p className={'app-item-description'}>
              <i>{subtitle}</i>
            </p>
          ) : null}
        </div>
      </div>
    );
  }

  private handleAddButtonClick = (event: MouseEvent): void => {
    const { handleAddMeal } = this.props;
    event.cancelable = true;
    event.stopPropagation();
    handleAddMeal();
  };

  private handleRemoveButtonClick = (event: MouseEvent): void => {
    const { handleRemoveMeal } = this.props;
    event.cancelable = true;
    event.stopPropagation();
    handleRemoveMeal();
  };
}

const mapStateToProps = (state: State, { mealId }: MealCardProps): MealCardComponentProps => {
  const meal = getMealById(mealId)(state);
  if (meal) {
    const { name, subtitle, imageUrl } = meal;
    const mealCount = getCountSelectedOfMeal(meal.id)(state);
    return {
      imageUrl,
      title: name,
      subtitle,
      mealCount,
    };
  }

  return {
    imageUrl: '',
    title: 'Error',
    subtitle: undefined,
    mealCount: 0,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, { mealId }: MealCardProps): MealCardDispatchProps => ({
  handleAddMeal: (): void => {
    dispatch(selectMealAction(mealId));
  },
  handleRemoveMeal: (): void => {
    dispatch(unselectMealAction(mealId));
  },
});

export const MealCard = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MealCardComponent);
