import React, { Component } from 'react';
import './styles.scss';
import { CheeseProps, CheeseState } from './types';

export class Cheese extends Component<CheeseProps, CheeseState> {
  constructor(props: CheeseProps) {
    super(props);

    this.state = {
      isToggled: false,
    };
  }

  public render(): JSX.Element {
    const { children, title } = this.props;

    return (
      <div className={'cheese'}>
        <p className={'cheese__cheese-title'} onClick={this.toggleContent}>
          {title}
        </p>
        {this.state.isToggled ? children : null}
      </div>
    );
  }

  private toggleContent = (): void => {
    this.setState(({ isToggled }: CheeseState) => ({ isToggled: !isToggled }));
  };
}
