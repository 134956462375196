import React, { ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

class ScrollToTopComponent extends React.Component<RouteComponentProps> {
  public componentDidUpdate(prevProps: RouteComponentProps): void {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  public render(): ReactNode {
    return this.props.children;
  }
}

export const ScrollToTop = withRouter(ScrollToTopComponent);
