import { Layout } from 'antd';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import LogoSvg from './logo-dark.png';
import './styles.scss';

class HeaderComponent extends Component<RouteComponentProps & WithTranslation> {
  public render(): JSX.Element {
    const { children, i18n } = this.props;

    return (
      <Layout.Header className={'app-header-container'}>
        <div className={'app-content app-header-container__content'}>
          {children ? (
            <div onClick={this.goHome} className={'app-header-container__content__side-content'}>
              <img className={'app-logo'} src={LogoSvg} alt={'Freshly'} />
            </div>
          ) : null}
          {!children ? <img src={LogoSvg} className={'app-logo'} alt={'Freshly'} /> : null}
          {children}
          <div className={'app-header-container__content__side-content'}>
            <div className={'app-header-container__content__side-content__languages-wrapper'}>
              <p
                onClick={(): void => {
                  i18n.changeLanguage('en');
                }}
              >
                {'EN'}
              </p>
              <p
                onClick={(): void => {
                  i18n.changeLanguage('ar');
                }}
              >
                {'عربي'}
              </p>
            </div>
          </div>
        </div>
      </Layout.Header>
    );
  }

  private goHome = (): void => {
    this.props.history.push('/');
  };
}

export const Header = withRouter(withTranslation()(HeaderComponent));
