import firebase from 'firebase';
import { store } from '../../App';
import { config } from './firebaseConfig';

import { Meal } from '../../models/meal';
import { getActiveDeliveryDay } from '../../reducers/selectors/deliveryDays';
import { getMeals, getSelectedMealsIds } from '../../reducers/selectors/meals';
import { getSelectedPlanId } from '../../reducers/selectors/plans';
import {
  getUserAddress1,
  getUserAddress2,
  getUserCity,
  getUserEmail,
  getUserFirstName,
  getUserLastName,
  getUserPaymentMethod,
  getUserPhone,
} from '../../reducers/selectors/user';

export class FirebaseDatabaseWriter {
  private database: firebase.database.Database;

  constructor() {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }

    this.database = firebase.database();
  }

  public writeUserData = (): void => {
    const userId =
      localStorage.getItem('identity') ||
      '_' +
        Math.random()
          .toString(36)
          .substr(2, 9);

    const userRef = this.database.ref('users');
    const state = store.getState();

    const email = getUserEmail(state);
    const city = getUserCity(state);
    const plan = getSelectedPlanId(state);
    const deliveryDay = getActiveDeliveryDay(state);
    const selectedMealsIds = getSelectedMealsIds(state);
    const meals = getMeals(state)
      .filter((meal: Meal) => selectedMealsIds.includes(meal.id))
      .map((meal: Meal) => ({ id: meal.id, name: meal.name }));
    const firstName = getUserFirstName(state);
    const lastName = getUserLastName(state);
    const userAddress1 = getUserAddress1(state);
    const userAddress2 = getUserAddress2(state);
    const phone = getUserPhone(state);
    const paymentMethod = getUserPaymentMethod(state);

    userRef.child(userId).set({
      email,
      city,
      plan,
      deliveryDay,
      meals,
      firstName,
      lastName,
      userAddress1,
      userAddress2: userAddress2 || 'Not set',
      phone,
      paymentMethod,
    });
  };
}
