import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import ar from './translations/ar.json';
import en from './translations/en.json';

const detector = {
  name: 'pathnameDetector',

  lookup: (): string => {
    const pathname = window.location.pathname;
    if (pathname.includes('/sa' || '/qa')) {
      return 'ar';
    }
    return 'en';
  },

  cacheUserLanguage(): void {
    // TODO: do something
  },
};

const resources = {
  en: {
    translation: en,
  },
  ar: {
    translation: ar,
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(detector);
languageDetector.init();

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    resources,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },

    detection: {
      order: ['pathnameDetector', 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    },
  });

export default i18n;
