import { Reducer } from 'redux';
import { CurrencyActionsUnion, CurrencyActionTypes } from '../actions/currency';
import { AppLocalization } from '../models/appLocalization';

export interface State {
  currency: AppLocalization;
}

const initialState: State = {
  currency: 'bh',
};

export const reducer: Reducer<State, CurrencyActionsUnion> = (
  state: State = initialState,
  action: CurrencyActionsUnion,
): State => {
  switch (action.type) {
    case CurrencyActionTypes.SetCurrency:
      return {
        ...state,
        currency: action.payload.currency,
      };

    default:
      return state;
  }
};

export const getCurrency = ({ currency }: State): string => currency;
