import { Button } from 'antd';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import './styles.scss';
import { PlanCardProps } from './types';

class PlanCardComponent extends Component<PlanCardProps & WithTranslation> {
  public render(): JSX.Element {
    const { header, secondaryHeader, mealPrice, weekPrice, buttonTitle, imageUrl, t } = this.props;

    return (
      <div className={'plan-card'} onClick={this.handleClick}>
        <img className={'plan-card__card-image'} alt={header} src={imageUrl} />
        <div className={'plan-card__card-body'}>
          <div className={'plan-card__card-body__big-header'}>
            <h1>{header}</h1>
            <h2>{secondaryHeader}</h2>
          </div>
          <h2 className={'plan-card__card-body__small-header'}>{`${header} ${secondaryHeader}`}</h2>
          <div className={'plan-card__card-body__description-container'}>
            <p>{`${mealPrice} / ${t('common.meal')}`}</p>
            <span className={'bullet'} />
            <p>{`${weekPrice} / ${t('common.week')}`}</p>
          </div>
          <Button className={'plan-card__card-body__button'} size={'large'} type={'primary'} block>
            {buttonTitle || t('plans.selectThisPlan')}
          </Button>
        </div>
      </div>
    );
  }

  private handleClick = (): void => {
    const { id, onClick } = this.props;

    if (onClick) {
      onClick(id);
    }
  };
}

export const PlanCard = withTranslation()(PlanCardComponent);
