import { createSelector, Selector } from 'reselect';
import { getMealsState } from '.';
import { State } from '..';
import { Meal } from '../../models/meal';

import * as fromMeals from '../meals';

export const getMeals = createSelector(
  getMealsState,
  fromMeals.getMeals,
);
export const getMealById = (id: number): Selector<State, Meal | undefined> =>
  createSelector(
    getMealsState,
    fromMeals.getMealById(id),
  );
export const getCountSelectedOfMeal = (id: number): Selector<State, number> =>
  createSelector(
    getMealsState,
    fromMeals.getCountSelectedOfMeal(id),
  );
export const getSelectedMealsIds = createSelector(
  getMealsState,
  fromMeals.getSelectedMealsIds,
);
export const getCountOfSelectedMeals = createSelector(
  getMealsState,
  fromMeals.getCountOfSelectedMeals,
);
