import { Button, Layout } from 'antd';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Meal } from '../../../models/meal';
import { State } from '../../../reducers';
import { getCountOfSelectedMeals, getMeals, getSelectedMealsIds } from '../../../reducers/selectors/meals';
import { getSelectedPlanId } from '../../../reducers/selectors/plans';
import { CartItem } from '../../local/CartItem';
import { MealCard } from '../../local/MealCard';
import { MealModal } from './components/MealModal';
import './styles.scss';
import { SelectMealsProps, SelectMealsState } from './types';

const { Sider, Content } = Layout;

export class SelectMealsComponent extends Component<
  SelectMealsProps & RouteComponentProps & WithTranslation,
  SelectMealsState
> {
  constructor(props: SelectMealsProps & RouteComponentProps & WithTranslation) {
    super(props);

    this.state = {
      collapsed: window.screen.width > 500 ? false : true,
      mealDetailsId: null,
    };
  }

  public render(): JSX.Element {
    const { meals, selectedMealsIds, mealsToSelect, selectedMealsCount, t } = this.props;
    const difference = Math.abs(selectedMealsCount - mealsToSelect);

    return (
      <React.Fragment>
        <MealModal
          visible={Boolean(this.state.mealDetailsId)}
          mealId={this.state.mealDetailsId}
          onCancel={this.closeMealModal}
        />
        <div className={'select-meals-page'}>
          <div className={'app-content'}>
            <Layout className={'select-meals-page__layout'}>
              <div className={'select-meals-page__layout__collapse-button-container'}>
                <p className={'select-meals-page__layout__collapse-button-container__info'}>
                  {`${selectedMealsCount} / ${mealsToSelect}`}
                </p>
                <Button onClick={this.handleCollapse}>{this.state.collapsed ? '<' : '>'}</Button>
              </div>
              <Content className={'select-meals-page__layout__content'}>
                <div className={'select-meals-page__layout__content__container'}>
                  {meals.map(({ id }: Meal) => (
                    <div key={id} className={'select-meals-page__layout__content__container__item'}>
                      <MealCard
                        onCardClick={(): void => {
                          this.openMealModal(id);
                        }}
                        mealId={id}
                      />
                    </div>
                  ))}
                </div>
                {difference ? (
                  <p className={'select-meals-page__layout__content__note'}>{`${
                    selectedMealsCount > mealsToSelect ? t('meals.remove') : t('meals.add')
                  } ${difference} ${t('meals.mealsToContinue')}`}</p>
                ) : null}
                <Button
                  className={'select-meals-page__layout__content__button'}
                  type={'primary'}
                  block
                  onClick={this.goNext}
                  disabled={selectedMealsCount !== mealsToSelect}
                >
                  {t('common.next')}
                </Button>
              </Content>
              <Sider
                collapsible
                collapsedWidth={0}
                trigger={null}
                collapsed={this.state.collapsed}
                className={'select-meals-page__layout__side-bar'}
                width={300}
              >
                <div className={'select-meals-page__layout__side-bar__content'}>
                  {selectedMealsCount ? <h3 className={'app-header'}>{`Choose ${mealsToSelect} meals`}</h3> : null}
                  <div className={'select-meals-page__layout__side-bar__content__meals-container'}>
                    {selectedMealsIds.map((id: number) => (
                      <CartItem key={id} mealId={id} showControls />
                    ))}
                  </div>
                  {difference ? (
                    <p className={'select-meals-page__layout__side-bar__content__note'}>{`${
                      selectedMealsCount > mealsToSelect ? t('meals.remove') : t('meals.add')
                    } ${difference} ${t('meals.mealsToContinue')}`}</p>
                  ) : null}
                  <div className={'select-meals-page__layout__side-bar__content__button-container'}>
                    <Button
                      className={'select-meals-page__layout__side-bar__content__button-container__button'}
                      type={'primary'}
                      block
                      onClick={this.goNext}
                      disabled={selectedMealsCount !== mealsToSelect}
                    >
                      {t('common.next')}
                    </Button>
                  </div>
                </div>
              </Sider>
            </Layout>
          </div>
        </div>
      </React.Fragment>
    );
  }

  private handleCollapse = (): void => {
    this.setState(({ collapsed }: SelectMealsState) => ({ collapsed: !collapsed }));
  };

  private goNext = (): void => {
    const { selectedMealsCount, mealsToSelect } = this.props;
    if (mealsToSelect === selectedMealsCount) {
      const { history } = this.props;
      history.push('checkout');
    }
  };

  private openMealModal = (id: number): void => {
    this.setState({ mealDetailsId: id });
  };

  private closeMealModal = (): void => {
    this.setState({ mealDetailsId: null });
  };
}

const mapStateToProps = (state: State): SelectMealsProps => ({
  meals: getMeals(state),
  mealsToSelect: getSelectedPlanId(state) || 0,
  selectedMealsIds: getSelectedMealsIds(state),
  selectedMealsCount: getCountOfSelectedMeals(state),
});

export const SelectMeals = connect(mapStateToProps)(withTranslation()(SelectMealsComponent));
