export const FillUserInfoFormName = 'fill-user-info-form';

export enum FillUserInfoFormFieldNames {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
}

export interface FillUserInfoFormValues {
  [FillUserInfoFormFieldNames.FirstName]: string;
  [FillUserInfoFormFieldNames.LastName]: string;
  [FillUserInfoFormFieldNames.Email]: string;
}
