import { Col, Icon, Layout } from 'antd';
import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { setCurrencyAction } from '../../../actions/currency';
import { setCityAction, setEmailAction } from '../../../actions/user';
import { AppAction } from '../../../models/appAction';
import { AppLocalization } from '../../../models/appLocalization';
import { HowItWorksRecord } from '../../../models/howItWorksRecord';
import { ImageWithTitle } from '../../../models/imageWithTitle';
import { Review } from '../../../models/review';
import { Header } from '../../local/Header';
import LogoSvg from '../../local/Header/logo-dark.png';
import { SignInForm } from '../../local/SignInForm';
import { SignInFormValues } from '../../local/SignInForm/types';
import { howItWorksSteps } from './data/howItWorksSteps';
import { mealAdvantages } from './data/mealAdvantages';
import { reviews } from './data/reviews';
import './styles.scss';
import { HomeProps } from './types';

export class HomeComponent extends Component<RouteComponentProps & WithTranslation & HomeProps> {
  public componentDidMount(): void {
    const {
      match: { url },
      handleSetCurrency,
    } = this.props;

    if (url.includes('/')) {
      const locality = url.substring(url.indexOf('/') + 1);
      try {
        const localization: AppLocalization = locality as AppLocalization;
        if (localization.length) {
          handleSetCurrency(localization);
        }
      } catch (error) {
        // tslint:disable-next-line:no-console
        console.log('Incorrect localization: ', error);
      }
    }
  }

  public render(): JSX.Element {
    const { t } = this.props;

    return (
      <div className={'home-page'}>
        <Header />
        <div className={'header-spacer'} />
        <div className={'home-page__image-container primary-image'}>
          <div className={'app-content'}>
            <div className={'home-page__form-wrapper home-page__push-right'}>
              <h1 className={'app-section-header'}>{t('home.title')}</h1>
              <div className={'home-page__form-wrapper__description-container'}>
                <p>{t('home.fresh')}</p>
                <span className={'bullet'} />
                <p>{t('home.healthy')}</p>
                <span className={'bullet'} />
                <p>{t('home.convenient')}</p>
              </div>
              <SignInForm onSubmit={this.handleSignIn} />
            </div>
          </div>
        </div>

        <div className={'home-page__heading-container home-page__how-it-works'}>
          <div className={'app-content'}>
            <h1 className={'app-section-header'}>{t('home.howItWorks.title')}</h1>
            <div className={'home-page__how-it-works__item-content'}>
              <div className={'home-page__how-it-works__item-content__line'} />
              {howItWorksSteps.map(({ description, imageUrl, title }: HowItWorksRecord) => (
                <div key={title} className={'home-page__how-it-works__item-content__item'}>
                  <img src={imageUrl} alt={t(title)} />
                  <h4>{t(title)}</h4>
                  <p>{t(description)}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <Col span={24}>
          <div
            className={
              'home-page__image-container secondary-image home-page__heading-container home-page__meal-advantages'
            }
          >
            <div className={'overlay'} />
            <h1 className={'app-section-header'}>{t('home.mealAdvantages.title')}</h1>
            <div className={'home-page__meal-advantages__container'}>
              {mealAdvantages.map(({ title, imageUrl }: ImageWithTitle) => (
                <div key={title} className={'home-page__meal-advantages__container__item'}>
                  <img alt={t(title)} src={imageUrl} />
                  <h3>{t(title)}</h3>
                </div>
              ))}
            </div>
          </div>
        </Col>

        <Col span={24} className={'home-page__heading-container home-page__reviews-container'}>
          <div className={'app-content'}>
            <h1 className={'app-section-header'}>{t('home.reviews.title')}</h1>
            <AliceCarousel
              responsive={{
                0: {
                  items: 0,
                },
                1024: {
                  items: 3,
                },
              }}
              items={reviews.map(({ id, stars, header, review, author }: Review) => (
                <div key={id} className={'home-page__reviews-container__item'}>
                  <div>
                    {[...Array(stars)].map((item: any, index: number) => (
                      <Icon
                        key={index}
                        type={'star'}
                        theme={'filled'}
                        className={'home-page__reviews-container__item__star-icon'}
                      />
                    ))}
                  </div>
                  <p className={'home-page__reviews-container__item__header'}>{t(header)}</p>
                  <p className={'home-page__reviews-container__item__content'}>{t(review)}</p>
                  <p className={'home-page__reviews-container__item__author'}>{t(author)}</p>
                </div>
              ))}
              dotsDisabled
            />
          </div>
        </Col>

        <div className={'home-page__image-container third-image'}>
          <div className={'home-page__form-wrapper center'}>
            <div className={'home-page__form-wrapper__description-container'}>
              <p>{t('home.minutes')}</p>
            </div>
            <SignInForm onSubmit={this.handleSignIn} />
          </div>
        </div>

        <Layout.Footer>
          <div className={'home-page__footer'}>
            <div className={'home-page__footer__column'}>
              <img className={'app-logo'} src={LogoSvg} alt={'logo'} />
              <p>{'© Chefplans Inc.'}</p>
            </div>
          </div>
        </Layout.Footer>
      </div>
    );
  }

  private handleSignIn = (values: SignInFormValues): void => {
    const { city, email } = values;
    this.props.handleSetCity(city);
    this.props.handleSetEmail(email);
    this.props.history.push('/join-now');
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AppAction>): HomeProps => ({
  handleSetCity: (city: string): void => {
    dispatch(setCityAction(city));
  },
  handleSetEmail: (email: string): void => {
    dispatch(setEmailAction(email));
  },
  handleSetCurrency: (currency: AppLocalization): void => {
    dispatch(setCurrencyAction(currency));
  },
});

export const Home = connect(
  null,
  mapDispatchToProps,
)(withTranslation()(HomeComponent));
