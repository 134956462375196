import { Button } from 'antd';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { setDeliveryDayAction } from '../../../actions/deliveryDays';
import { AppAction } from '../../../models/appAction';
import { State } from '../../../reducers';
import { getActiveDeliveryDay, getDeliveryDays } from '../../../reducers/selectors/deliveryDays';
import { CommonQuestions } from '../../local/CommonQuestions';
import './styles.scss';
import { ChooseDeliveryDayDispatchProps, ChooseDeliveryDayProps, ChooseDeliveryDayStateProps } from './types';

export class ChooseDeliveryDayComponent extends Component<RouteComponentProps & WithTranslation & ChooseDeliveryDayProps> {
  public render(): JSX.Element {
    const { days, t } = this.props;

    return (
      <div className={'choose-delivery-day-page'}>
        <h3 className={'app-header'}>{t('deliveryDay.title')}</h3>
        <ul className={'choose-delivery-day-page__days-section'}>{days.map(this.renderDay)}</ul>
        <Button
          className={'choose-delivery-day-page__next-button'}
          type={'primary'}
          size={'large'}
          onClick={this.goNext}
        >
          {t('common.next')}
        </Button>
        <div className={'app-content'}>
          <CommonQuestions />
        </div>
      </div>
    );
  }

  private renderDay = (day: string): JSX.Element => {
    const { selectedDay, handleSelectDay, t } = this.props;

    return (
      <li
        className={'choose-delivery-day-page__days-section__item'}
        key={day}
        onClick={(): void => {
          handleSelectDay(day);
        }}
      >
        {selectedDay === day ? <div className={'choose-delivery-day-page__days-section__item__pointer'} /> : null}
        <p>{t(`common.${day}`)}</p>
      </li>
    );
  };

  private goNext = (): void => {
    const { history } = this.props;
    history.push('meals');
  };
}

const mapStateToProps = (state: State): ChooseDeliveryDayStateProps => ({
  days: getDeliveryDays(state),
  selectedDay: getActiveDeliveryDay(state),
});
const mapDispatchToProps = (dispatch: Dispatch<AppAction>): ChooseDeliveryDayDispatchProps => ({
  handleSelectDay: (day: string): void => {
    dispatch(setDeliveryDayAction(day));
  },
});

export const ChooseDeliveryDay = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ChooseDeliveryDayComponent));
