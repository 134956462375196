import { Review } from '../../../../models/review';

export const reviews: Review[] = [
  {
    id: 1,
    stars: 5,
    header: 'home.reviews.first.short',
    review: 'home.reviews.first.review',
    author: 'home.reviews.first.author',
  },
  {
    id: 2,
    stars: 5,
    header: 'home.reviews.second.short',
    review: 'home.reviews.second.review',
    author: 'home.reviews.second.author',
  },
  {
    id: 3,
    stars: 5,
    header: 'home.reviews.third.short',
    review: 'home.reviews.third.review',
    author: 'home.reviews.third.author',
  },
];
