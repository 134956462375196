export const FillDeliveryAddressFormName = 'fill-delivery-address-form';

export enum FillDeliveryAddressFormFieldNames {
  FullName = 'full-name',
  AddressLine1 = 'address-line-1',
  AddressLine2 = 'address-line-2',
  City = 'city',
  Phone = 'phone',
}

export interface FillDeliveryAddressFormValues {
  [FillDeliveryAddressFormFieldNames.FullName]: string;
  [FillDeliveryAddressFormFieldNames.AddressLine1]: string;
  [FillDeliveryAddressFormFieldNames.AddressLine2]: string;
  [FillDeliveryAddressFormFieldNames.City]: string;
  [FillDeliveryAddressFormFieldNames.Phone]: string;
}
