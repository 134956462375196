import { AppAction } from '../models/appAction';

export enum MealActionTypes {
  SelectMeal = '[Meal] SelectMeal',
  UnselectMeal = '[Meal] UnselectMeal',
  SetSort = '[Meal] SetSort',
}

interface SelectMealAction extends AppAction<{ id: number }> {
  type: typeof MealActionTypes.SelectMeal;
}
export const selectMealAction: (id: number) => SelectMealAction = (id: number): SelectMealAction => ({
  type: MealActionTypes.SelectMeal,
  payload: {
    id,
  },
});

interface UnselectMealAction extends AppAction<{ id: number }> {
  type: typeof MealActionTypes.UnselectMeal;
}
export const unselectMealAction: (id: number) => UnselectMealAction = (id: number): UnselectMealAction => ({
  type: MealActionTypes.UnselectMeal,
  payload: {
    id,
  },
});

interface SetSortAction extends AppAction<{ sort: string }> {
  type: typeof MealActionTypes.SetSort;
}
export const setSortAction: (sort: string) => SetSortAction = (sort: string): SetSortAction => ({
  type: MealActionTypes.SetSort,
  payload: {
    sort,
  },
});

export type MealActionsUnion = SelectMealAction | UnselectMealAction | SetSortAction;
