import { Button } from 'antd';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Validator } from '../../../helpers/Validator';
import { RadioField } from '../RadioField';
import './styles.scss';
import { SelectPaymentMethodFormFieldNames, SelectPaymentMethodFormName, SelectPaymentMethodFormValues } from './types';

class SelectPaymentMethodFormComponent extends Component<
  InjectedFormProps<SelectPaymentMethodFormValues> & WithTranslation
> {
  public render(): JSX.Element {
    const { handleSubmit, t } = this.props;

    return (
      <form className={'select-payment-method-form'} onSubmit={handleSubmit}>
        <Field
          name={SelectPaymentMethodFormFieldNames.PaymentMethod}
          component={RadioField}
          validate={[Validator.required]}
          options={[
            { label: t('checkout.paymentInfo.payCard'), value: 'Pay with Card' },
            { label: t('checkout.paymentInfo.payCash'), value: 'Pay with Cash on delivery' },
          ]}
        />
        <Button
          className={'select-payment-method-form__submit-button'}
          size={'large'}
          type={'primary'}
          block
          htmlType={'submit'}
        >
          {t('common.next')}
        </Button>
      </form>
    );
  }
}

export const SelectPaymentMethodForm = reduxForm<SelectPaymentMethodFormValues>({
  form: SelectPaymentMethodFormName,
})(withTranslation()(SelectPaymentMethodFormComponent));
