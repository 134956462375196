import { AppAction } from '../models/appAction';
import { AppLocalization } from '../models/appLocalization';

export enum CurrencyActionTypes {
  SetCurrency = '[Currency] SetCurrency',
}

interface SetCurrencyAction extends AppAction<{ currency: AppLocalization }> {
  type: typeof CurrencyActionTypes.SetCurrency;
}
export const setCurrencyAction = (currency: AppLocalization): SetCurrencyAction => ({
  type: CurrencyActionTypes.SetCurrency,
  payload: {
    currency,
  },
});

export type CurrencyActionsUnion = SetCurrencyAction;
