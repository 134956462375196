import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { getPlansState } from '.';
import { State } from '..';
import { Plan } from '../../models/plan';

import * as fromPlans from '../plans';

export const getPlans = createSelector(
  getPlansState,
  fromPlans.getCollection,
);
export const getSelectedPlanId = createSelector(
  getPlansState,
  fromPlans.getSelectedPlanId,
);
export const getPlanById = (id: number): Selector<State, Plan> =>
  createSelector(
    getPlansState,
    fromPlans.getPlanById(id),
  );
