export const getCurrencyNameByCode = (code: string): string => {
  switch (code) {
    case 'sa':
      return 'ريال';
    case 'ae':
      return 'AED';
    case 'kw':
      return 'KWD';
    case 'qa':
      return 'QA';

    default:
      return 'BHD';
  }
};
