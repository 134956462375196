import { Button } from 'antd';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Normalize } from '../../../helpers/Normalize';
import { Validator } from '../../../helpers/Validator';
import { TextInput } from '../TextInput';
import './styles.scss';
import { FillDeliveryAddressFormFieldNames, FillDeliveryAddressFormName, FillDeliveryAddressFormValues } from './types';

class FillDeliveryAddressFormComponent extends Component<
  InjectedFormProps<FillDeliveryAddressFormValues> & WithTranslation
> {
  public render(): JSX.Element {
    const { handleSubmit, t } = this.props;

    return (
      <form className={'fill-delivery-address-form'} onSubmit={handleSubmit}>
        <Field
          name={FillDeliveryAddressFormFieldNames.FullName}
          component={TextInput}
          label={t('checkout.deliveryAddress.fullName')}
          validate={[Validator.required]}
        />
        <Field
          name={FillDeliveryAddressFormFieldNames.AddressLine1}
          component={TextInput}
          label={t('checkout.deliveryAddress.addressLine1')}
          validate={[Validator.required]}
        />
        <Field
          name={FillDeliveryAddressFormFieldNames.AddressLine2}
          component={TextInput}
          label={t('checkout.deliveryAddress.addressLine2')}
        />
        <Field
          name={FillDeliveryAddressFormFieldNames.City}
          component={TextInput}
          label={t('common.city')}
          disabled
          validate={[Validator.required]}
        />
        <Field
          name={FillDeliveryAddressFormFieldNames.Phone}
          component={TextInput}
          label={t('checkout.deliveryAddress.phone')}
          validate={[Validator.required, Validator.phone]}
          normalize={Normalize.phone}
        />
        <Button
          size={'large'}
          className={'fill-user-info-form__submit-button'}
          type={'primary'}
          block
          htmlType={'submit'}
        >
          {t('common.next')}
        </Button>
      </form>
    );
  }
}

export const FillDeliveryAddressForm = reduxForm<FillDeliveryAddressFormValues>({
  form: FillDeliveryAddressFormName,
})(withTranslation()(FillDeliveryAddressFormComponent));
