import React, { Component } from 'react';
import './styles.scss';
import { TileProps } from './types';

export class Tile extends Component<TileProps> {
  public render(): JSX.Element {
    const { header, children } = this.props;

    return (
      <div className={'tile'}>
        <h3>{header}</h3>
        {children ? <div className={'tile__content-wrapper'}>{children}</div> : null}
      </div>
    );
  }
}
