import { createSelector } from 'reselect';
import { getUserState } from '.';

import * as fromUser from '../user';

export const getUserEmail = createSelector(
  getUserState,
  fromUser.getEmail,
);
export const getUserCity = createSelector(
  getUserState,
  fromUser.getCity,
);
export const getUserFirstName = createSelector(
  getUserState,
  fromUser.getFirstName,
);
export const getUserLastName = createSelector(
  getUserState,
  fromUser.getLastName,
);
export const getUserAddress1 = createSelector(
  getUserState,
  fromUser.getAddress1,
);
export const getUserAddress2 = createSelector(
  getUserState,
  fromUser.getAddress2,
);
export const getUserPhone = createSelector(
  getUserState,
  fromUser.getPhone,
);
export const getUserPaymentMethod = createSelector(
  getUserState,
  fromUser.getPaymentMethod,
);
