import { AppAction } from '../models/appAction';

export enum UserActionTypes {
  SetEmail = '[User] SetEmail',
  SetCity = '[User] SetCity',
  SetFirstName = '[User] SetFirstName',
  SetLastName = '[User] SetLastName',
  SetAddress1 = '[User] SetAddress1',
  SetAddress2 = '[User] SetAddress2',
  SetPhone = '[User] SetPhone',
  SetPaymentMethod = '[User] SetPaymentMethod',
}

interface SetEmailAction extends AppAction<{ email: string }> {
  type: typeof UserActionTypes.SetEmail;
}
export const setEmailAction: (email: string) => SetEmailAction = (email: string): SetEmailAction => ({
  type: UserActionTypes.SetEmail,
  payload: {
    email,
  },
});

interface SetCityAction extends AppAction<{ city: string }> {
  type: typeof UserActionTypes.SetCity;
}
export const setCityAction: (city: string) => SetCityAction = (city: string): SetCityAction => ({
  type: UserActionTypes.SetCity,
  payload: {
    city,
  },
});

interface SetFirstNameAction extends AppAction<{ firstName: string }> {
  type: typeof UserActionTypes.SetFirstName;
}
export const setFirstNameAction: (firstName: string) => SetFirstNameAction = (
  firstName: string,
): SetFirstNameAction => ({
  type: UserActionTypes.SetFirstName,
  payload: {
    firstName,
  },
});

interface SetLastNameAction extends AppAction<{ lastName: string }> {
  type: typeof UserActionTypes.SetLastName;
}
export const setLastNameAction: (lastName: string) => SetLastNameAction = (lastName: string): SetLastNameAction => ({
  type: UserActionTypes.SetLastName,
  payload: {
    lastName,
  },
});

interface SetAddress1Action extends AppAction<{ address: string }> {
  type: typeof UserActionTypes.SetAddress1;
}
export const setAddress1Action = (address: string): SetAddress1Action => ({
  type: UserActionTypes.SetAddress1,
  payload: {
    address,
  },
});

interface SetAddress2Action extends AppAction<{ address: string }> {
  type: typeof UserActionTypes.SetAddress2;
}
export const setAddress2Action = (address: string): SetAddress2Action => ({
  type: UserActionTypes.SetAddress2,
  payload: {
    address,
  },
});

interface SetPhoneAction extends AppAction<{ phone: string }> {
  type: typeof UserActionTypes.SetPhone;
}
export const setPhoneAction = (phone: string): SetPhoneAction => ({
  type: UserActionTypes.SetPhone,
  payload: {
    phone,
  },
});

interface SetPaymentMethodAction extends AppAction<{ paymentMethod: string }> {
  type: typeof UserActionTypes.SetPaymentMethod;
}
export const setPaymentMethodAction = (paymentMethod: string): SetPaymentMethodAction => ({
  type: UserActionTypes.SetPaymentMethod,
  payload: {
    paymentMethod,
  },
});

export type UserActionsUnion =
  | SetEmailAction
  | SetCityAction
  | SetFirstNameAction
  | SetLastNameAction
  | SetAddress1Action
  | SetAddress2Action
  | SetPhoneAction
  | SetPaymentMethodAction;
