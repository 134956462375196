import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { setActivePlanAction } from '../../../actions/plans';
import { plans } from '../../../data/plans';
import { AppAction } from '../../../models/appAction';
import { AppLocalization } from '../../../models/appLocalization';
import { Plan } from '../../../models/plan';
import { State } from '../../../reducers';
import { getCurrency } from '../../../reducers/selectors/currency';
import { CommonQuestions } from '../../local/CommonQuestions';
import { PlanCard } from '../../local/PlanCard';
import './styles.scss';
import { ChoosePlanDispatchProps, ChoosePlanStateProps } from './types';
import { getCurrencyNameByCode } from '../../../helpers/CurrencyNamer';

class ChoosePlanComponent extends Component<
  RouteComponentProps & WithTranslation & ChoosePlanDispatchProps & ChoosePlanStateProps
> {
  public render(): JSX.Element {
    const { t, currency } = this.props;

    return (
      <div className={'choose-plan-page'}>
        <h3 className={'app-header'}>{t('plans.choosePlan.title')}</h3>
        <p className={'app-section-description'}>{t('plans.choosePlan.description')}</p>
        <div className={'app-content'}>
          <div className={'choose-plan-page__plans-row'}>
            {plans.map(({ id, mealsCount, imageUrl, mealPrice, weekPrice }: Plan) => (
              <div key={id} className={'choose-plan-page__plans-row__item'}>
                <PlanCard
                  id={id}
                  header={`${mealsCount}`}
                  secondaryHeader={t('common.mealsInWeek').toUpperCase()}
                  imageUrl={imageUrl}
                  mealPrice={`${getCurrencyNameByCode(currency)} ${mealPrice[currency]}`}
                  weekPrice={`${getCurrencyNameByCode(currency)} ${weekPrice[currency]}`}
                  onClick={this.selectPlan}
                />
              </div>
            ))}
          </div>
        </div>
        <h3 className={'app-header'}>{t('plans.freeShipping.title')}</h3>
        <p className={'app-section-description'}>{t('plans.freeShipping.description')}</p>
        <div className={'app-content'}>
          <CommonQuestions />
        </div>
      </div>
    );
  }

  private selectPlan = (id: number): void => {
    const {
      history,
      match: { url },
      handleChoosePlan,
    } = this.props;
    handleChoosePlan(id);
    history.push(`${url}/day`);
  };
}

const mapStateToProps = (state: State): ChoosePlanStateProps => ({
  currency: getCurrency(state) as AppLocalization,
});

const mapDispatchToProps = (dispatch: Dispatch<AppAction>): ChoosePlanDispatchProps => ({
  handleChoosePlan: (id: number): void => {
    dispatch(setActivePlanAction(id));
  },
});

export const ChoosePlan = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ChoosePlanComponent));
