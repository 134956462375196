import { State } from '..';

import * as fromCurrency from '../currency';
import * as fromDeliveryDays from '../deliveryDays';
import * as fromMeals from '../meals';
import * as fromPlans from '../plans';
import * as fromUser from '../user';

export const getDeliveryDaysState = ({ deliveryDays }: State): fromDeliveryDays.State => deliveryDays;
export const getMealsState = ({ meals }: State): fromMeals.State => meals;
export const getPlansState = ({ plans }: State): fromPlans.State => plans;
export const getUserState = ({ user }: State): fromUser.State => user;
export const getCurrencyState = ({ currency }: State): fromCurrency.State => currency;
